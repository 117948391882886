import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

import Chartist from "chartist";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons

import People from "@material-ui/icons/People";
import ErrorIcon from "@material-ui/icons/Error";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import FaceIcon from "@material-ui/icons/Face";
import SettingsCellIcon from "@material-ui/icons/SettingsCell";

import PeopleOutline from "@material-ui/icons/PeopleOutline";
import Local from "@material-ui/icons/LocalConvenienceStore";
import Store from "@material-ui/icons/Store";
import LocalShipping from "@material-ui/icons/LocalShipping";
import List from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server, serverUrl } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import authService from "services/auth.service";

import history from 'services/history';

import authHeader from "services/auth-header"



const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [dailyUsers, setDailyUsers] = useState(0);
  const [monthlyUsers, setMonthlyUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [thisMonthUsers, setThisMonthUsers] = useState(0);
  const [deviceRatio, setDeviceRatio] = useState("");
  const [guiaErrors, setGuiaErrors] = useState(0);
  const [bodegajeErrors, setBodegajeErrors] = useState(0);
  const [liberacionErrors, setLiberacionErrors] = useState(0);
  const [weeklyGuias, setWeeklyGuias] = useState({
    data: {
      labels: ["D", "L", "M", "M", "J", "V", "S"],
      series: [[0, 0, 0, 0, 0, 0, 0]],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      high: 50,
    },
  });

  const [weeklyBodegaje, setWeeklyBodegaje] = useState({
    data: {
      labels: ["D", "L", "M", "M", "J", "V", "S"],
      series: [[0, 0, 0, 0, 0, 0, 0]],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      high: 50,
    },
  });

  const [weeklyLiberacion, setWeeklyLiberacion] = useState({
    data: {
      labels: ["D", "L", "M", "M", "J", "V", "S"],
      series: [[0, 0, 0, 0, 0, 0, 0]],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      high: 50,
    },
  });

  useEffect(() => {


    if(authService.getCurrentUser() == null){
      history.push("/login")
      window.location.reload();
    }


    fetch(serverUrl + "/analytics/dailyRecords", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()
      }),
    })
      .then((res) => res.json())
      .then((response) => {

        if(response.success == false) {
          history.push("/login");
          authService.logout();
          window.location.reload(); 
        }
        else{
          setDailyUsers(response.count);

        }
        //  setCommitHistory(response.items);
        //  setIsLoading(false);

      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/monthlyRecords", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);

        setMonthlyUsers(response.count);
      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/countRegisteredUsers", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);

        setTotalUsers(response.count);
      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/thisMonthUsers", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);

        setThisMonthUsers(response.count);
      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/deviceRatio", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);

        var android = response.android;
        var ios = response.ios;

        var total = android + ios;

        var ratio =
          ((android / total) * 100).toFixed(0) +
          "% / " +
          ((ios / total) * 100).toFixed(0) +
          "%";

        setDeviceRatio(ratio);
      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/errorsCategories", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);
        console.log(response);
        setGuiaErrors(response.guias);
        setBodegajeErrors(response.bodegaje);
        setLiberacionErrors(response.liberacion);
        // setErrors(response.count)
      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/guiasSemana", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);
        console.log(response);

        var max = 1;
        response.forEach(function (value) {
          if (value > max) {
            max = value;
          }
        });

        setWeeklyGuias({
          data: {
            labels: ["D", "L", "M", "M", "J", "V", "S"],
            series: [response],
          },
          options: {
            lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: parseInt(max * 1.1),
          },
        });
      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/bodegajeSemana", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);
        console.log(response);

        var max = 1;
        response.forEach(function (value) {
          if (value > max) {
            max = value;
          }
        });

        setWeeklyBodegaje({
          data: {
            labels: ["D", "L", "M", "M", "J", "V", "S"],
            series: [response],
          },
          options: {
            lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: parseInt(max * 1.1),
          },
        });
      })
      .catch((error) => console.log(error));

    fetch(serverUrl + "/analytics/liberacionSemana", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()

      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //  setCommitHistory(response.items);
        //  setIsLoading(false);
        console.log(response);

        var max = 1;
        response.forEach(function (value) {
          if (value > max) {
            max = value;
          }
        });

        setWeeklyLiberacion({
          data: {
            labels: ["D", "L", "M", "M", "J", "V", "S"],
            series: [response],
          },
          options: {
            lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: parseInt(max * 1.1),
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="aerosan" stats icon>
              <CardIcon color="aerosan">
                <People />
              </CardIcon>
              <p className={classes.cardCategory}>Ingresos Diarios</p>
              <h3 className={classes.cardTitle}>
                {dailyUsers} <small></small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Success>
                  <Warning />
                </Success>
                <a href="/admin/usuarios">Ver lista de usuarios</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="aerosan" stats icon>
              <CardIcon color="aerosan">
                <People />
              </CardIcon>
              <p className={classes.cardCategory}>Ingresos Mensuales</p>
              <h3 className={classes.cardTitle}>
                {monthlyUsers} <small></small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Success>
                  <Warning />
                </Success>
                <a href="/admin/usuarios">Ver lista de usuarios</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="aerosan">
                <FaceIcon />
              </CardIcon>
              <p className={classes.cardCategory}>User Stickness </p>
              <h3 className={classes.cardTitle}>
                {" "}
                {((dailyUsers / monthlyUsers) * 100).toFixed(0)} %
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Estadistica diaria
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="aerosan" stats icon>
              <CardIcon color="aerosan">
                <GroupAddIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Nuevos Registros</p>
              <h3 className={classes.cardTitle}>
                {thisMonthUsers} <small></small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Ultimo mes
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="aerosan">
                <PeopleOutline />
              </CardIcon>
              <p className={classes.cardCategory}>Total registros </p>
              <h3 className={classes.cardTitle}> {totalUsers}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <People />
                Usuarios en el sistema
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="aerosan">
                <SettingsCellIcon />
              </CardIcon>
              <p className={classes.cardCategory}> Relacion Android/iOS</p>
              <h3 className={classes.cardTitle}>{deviceRatio}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <PhoneIphoneIcon />
                Tipo de dispositivo
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <h6>Estadisticas de Errores</h6>

      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="aerosan">
                <ErrorIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Errores Guias</p>
              <h3 className={classes.cardTitle}>{guiaErrors}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <DateRange />
                </Danger>
                Ultima semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="aerosan">
                <ErrorIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Errores Deposito Aduanero</p>
              <h3 className={classes.cardTitle}>{bodegajeErrors}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <DateRange />
                </Danger>
                Ultima semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="aerosan">
                <ErrorIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Errores Liberacion</p>
              <h3 className={classes.cardTitle}>{liberacionErrors}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <DateRange />
                </Danger>
                Ultima semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <h6>Estadisticas de consultas</h6>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="aerosanGradient">
              <ChartistGraph
                className="ct-chart"
                data={weeklyGuias.data}
                type="Line"
                options={weeklyGuias.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Guias </h4>
              <p className={classes.cardCategory}>Consulta diaria de Guias</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Ultima semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="aerosanGradient">
              <ChartistGraph
                className="ct-chart"
                data={weeklyBodegaje.data}
                type="Line"
                options={weeklyBodegaje.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Deposito Aduanero</h4>
              <p className={classes.cardCategory}>Liquidaciones generadas</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Ultima semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="aerosanGradient">
              <ChartistGraph
                className="ct-chart"
                data={weeklyLiberacion.data}
                type="Line"
                options={weeklyLiberacion.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Sistema de importaciones</h4>
              <p className={classes.cardCategory}>
                Preliquidaciones liberacion
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Ultima semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
