import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Footer from "components/Footer/Footer.js";
import AddAlert from "@material-ui/icons/AddAlert";
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from "components/Snackbar/Snackbar.js";


import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";

import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';

import Form from "react-validation/build/form";
import logo from "assets/img/aerosan-logo.png";



import { isEmail } from "validator";

import image from "assets/img/full-back.png";
import AuthService from "services/auth.service";
import history from 'services/history';


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  margin: {
    spacing: 10
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "-1",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      opacity: ".6"
    }
  },
  login: {
    zIndex: "4"
  },
  cardAvatar: {
    maxWidth: "130px",
    maxHeight: "130px",
  }
};

const useStyles = makeStyles(styles);

export default function Login() {
  const classes = useStyles();

  const [ email, setEmail ] = useState("");
  const [password,setPassword] = useState("");

  const [tc, setTC] = useState(false);
  const [tcMessage, setTCMessage] = useState("")


  function handleLogin(e){
    e.preventDefault();
    if(email == "" || password == ""){
      return;
    }  

    setTC(false);

    AuthService.login(email,password).then (
      (result) => {
      if (result.success == true) {
          history.push('admin/dashboard')
          window.location.reload()
        }
        else{
          setTCMessage(result.message)
          setTC(true)
        }
      }

    )



  }

  function onChangeEmail(e){
    console.log(e.target.value)
    setEmail(e.target.value);
    }

    function onChangePassword(e){
      setPassword(e.target.value);
    }

  return (


    <div>
      <GridContainer
        justify={"center"}
        alignItems={"center"}
        align={"center"}
        direction={"column"}
        style={{ minHeight: "85vh", zIndex: 4, position: "relative" }}
      >
        <GridItem xs={6} sm={12} md={8}>
          <Card profile style={{ padding: 30 }}>
            <CardBody profile>
            <img  src={logo} width="100" height="100" className={classes.small} />

              
              <h3> Inicio de Sesión </h3>



              <Form
            onSubmit={handleLogin}
          
          >
            <div className="form-group">
              <FormControl className={classes.margin} style={{ padding: 10 }}>

                <InputLabel htmlFor="input-with-icon-adornment"></InputLabel>
                <Input
                  id="login-username"
                  placeholder="Correo electronico"
                  type="email"
                  onChange={onChangeEmail}
                  startAdornment={
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  }
                />
                 </FormControl>
                 <p></p>
                  <FormControl className={classes.margin} style={{ padding: 10 }}>
                <InputLabel htmlFor="input-with-icon-adornment"></InputLabel>
                <Input
                  id="login-password"
                  placeholder="Clave"
                  type="password"
                  onChange={onChangePassword}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                />
                </FormControl>



              <p className={classes.description}>

              </p>
              <p></p>
              <Button type="submit"  color="aerosan" round>
                Ingresar
              </Button>

              <Snackbar
                  place="tc"
                  color="danger"
                  icon={ErrorIcon}
                  message={tcMessage}
                  open={tc}
                  closeNotification={() => setTC(false)}
                  close
                />
              </div>
            </Form>
            </CardBody>
            

            <CardFooter>
              <p className={classes.right}>
                <span>
                  &copy; {1900 + new Date().getYear()} Aerosan S.A.S
                  
                </span>
              </p>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <div
        className={classes.background}
        style={{ backgroundImage: "url(" + image + ")" }}
      />
    </div>
  );
}
