import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { serverUrl } from "variables/general.js";

import authService from "services/auth.service";

import history from 'services/history';
import authHeader from "services/auth-header"

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Usuarios() {
  const classes = useStyles();

  

  const [users, setUsers] = useState([]);
  useEffect(() => {

    if(authService.getCurrentUser() == null){
      history.push("/login")
      window.location.reload();
    }

    fetch(serverUrl + "/analytics/registeredUsers", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "x-access-token":authHeader()
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        
        if(response.success == false) {
          history.push("/login");
          authService.logout();
          window.location.reload(); 
        }
        else{
          setUsers(response);
          console.log(response);
        }
        //  setCommitHistory(response.items);
        //  setIsLoading(false);

        
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="aerosanGradient">
            <h4 className={classes.cardTitleWhite}>Usuarios registrados</h4>
            <p className={classes.cardCategoryWhite}>Aplicación Movil</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="aerosan"
              tableHead={[
                "Nombres",
                "Apellidos",
                "Email",
                "Sistema Operativo",
                "Última conexión",
                "Fecha registro",
              ]}
              tableData={users}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
