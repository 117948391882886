import axios from "axios";
import {serverUrl } from "variables/general.js";


class AuthService {
  login(username, password) {
    return axios
      .post(serverUrl + "/auth/adminLogin", {
        email: username,
        password: password
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
